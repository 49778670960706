import React from 'react';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import Section from '../components/Section/Section';

export const BlogPost = ({ title, content }) => (
  <article
    className="blog-post"
    itemScope
    itemType="http://schema.org/BlogPosting"
  >
    <ContentHeader title={title} />
    <Section>
      <div itemProp="articleBody">{content}</div>
    </Section>
  </article>
);
