import IconAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import IconAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import IconListUl from '@iconscout/react-unicons/icons/uil-list-ul';
import { Link } from 'gatsby';
import moment from 'moment/moment';
import React from 'react';
import './PostNav.scss';

const PostNav = ({ previous, next }) => {
  const iconSize = 30;
  const isNextAvailable =
    !!next &&
    (!next.frontmatter.date || moment().isAfter(moment(next.frontmatter.date)));

  return (
    <nav className="PostNav">
      <ul>
        <li>
          {previous && (
            <Link className={'Link'} to={previous.fields.slug} rel="prev">
              <IconAngleLeft size={iconSize} /> <br />
              <span className={'PostNav__link-label'}>Vorherige Folge</span>
              {/*← {previous.frontmatter.title}*/}
            </Link>
          )}
        </li>
        <li>
          <Link className={'Link'} to={'..#list'}>
            <IconListUl size={iconSize} /> <br />
            <span className={'PostNav__link-label'}>Alle Folgen</span>
          </Link>
        </li>
        <li>
          {next && isNextAvailable && (
            <Link className={'Link'} to={next.fields.slug} rel="next">
              <IconAngleRight size={iconSize} /> <br />
              <span className={'PostNav__link-label'}>Nächste Folge</span>
              {/*{next.frontmatter.title} →*/}
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default PostNav;
